 

function getter(json) {
   return function(key) {
      if (!json.hasOwnProperty(key)) {
         var errorStr = 'Property ' + key + ' does not exist!';
         throw errorStr;
      }
      return json[key];
   };
}

window.guide_constants = {
   DocumentWatch: getter({
      DOCTYPE_GUIDE: 'guide',
      LEVEL_EDIT: 'edit'
   }),

   fa: getter({
      FILES_O: '<i class="fa fa-files-o " ></i>',
      INFO_CIRCLE: '<i class="fa fa-info-circle " ></i>',
      PENCIL: '<i class="fa fa-pencil " ></i>',
      SEARCH_PLUS: '<i class="fa fa-search-plus " ></i>',
      TIMES: '<i class="fa fa-times " ></i>'
   }),

   GuideURI: getter({
      ADMIN_SUPPORT_LINK: '<a href="/Manage/support">',
      TAG_ADMIN_ANNOUNCEMENTS: '/Manage/announcements',
      TAG_COLLECTIONS_EDIT: '/Manage/Collections/Edit/',
      TAG_GUIDE_CREATE: '/Guide/new',
      TAG_GUIDE_HISTORY: '/Guide/history',
      TAG_FORMATTING_HELP: '/Help/Wiki_Formatting_And_Syntax',
      TAG_REMOTE_LOGIN: '/Guide/User/remote_login',
      VIEWGUIDENOQUERY: '/Guide/guide/zzzzz',
      USER_REVIEWS_LINK: '/User/Reviews',
   }),

   Release: getter({
      STATUS_PENDING: 'pending',
      STATUS_RELEASED: 'released',
      MAX_TITLE_LENGTH: '48'
   }),

   SpamReview: getter({
      VALUE_SPAM: 'spam',
      VALUE_HAM: 'ham'
   }),

   UserLib: getter({
      ERR_PASSWORD_MISMATCH: 'password_mismatch',
      ERR_PASSWORD_INVALID: 'password_invalid',
      ERR_USERNAME_INVALID: 'invalid_username',
      ERR_UNIQUE_NAME_INVALID: 'invalid_unique_name',
      ERR_EMAIL_INVALID: 'invalid_email',
      ERR_EMAIL_EXISTS: 'login_exists',
      ERR_EMAIL_VERIFICATION: 'email_verification_required',
      ERR_DISALLOWED: 'disallowed',
      ERR_USER_LIMIT: 'user_limit',
      ERR_UNKNOWN: 'default',
      ERR_CAPTCHA_FAIL: 'captcha_fail',
      ERR_TERMS_AGREE: 'terms_agree',
   }),
}
